@import "../../../../styles/main.scss";
.partfolio-card {
    width: 47%;
    min-width: 156px;
    height: 170px;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    background: white;
    // background: rgba(248, 0, 158, 0.5);
    // background-image: url('../../../../assets/NotSB.svg');
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    // opacity: 0.6;
    transition: background 0.1s;
    position: relative;

    &:hover {
        background: rgba(128, 128, 128, 0.05);
        .asset-field-status-wrapper {
            opacity: 1;
        }
    }

    @media (max-width: 370px) {
        width: 45%;
        min-width: 150px;
    }
    @media (max-width: 357px) {
        min-width: 140px;
    }
    @media (max-width: 337px) {
        width: 100%;
        min-width: 156px;
    }

    .icon {
        width: 40px;
        height: 40px;
        margin-bottom: 4px;
        object-fit: contain;
        z-index: 4;
    }
    span {
        font-size: 14px;
        font-weight: 400;
        color: rgba(43, 47, 56, 1);
        z-index: 4;
        word-break: break-all;
    }
    p {
        font-size: 18px;
        font-weight: 600;
        margin-top: auto;
        z-index: 4;
    }

    .item-progress {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        z-index: 4;

        // @media (max-width: 337px) {
        //     justify-content: center;
        // }
        img {
            width: 16px;
            height: 16px;
        }

        span {
            font-size: 12px;
            font-weight: 600;
            color: rgba(39, 174, 96, 1);
            margin-left: 8px;

            &.danger {
                color: rgba(235, 87, 87, 1);
            }
        }
        p {
            margin: 0;
            padding: 0;
            font-size: 12px;
            font-weight: 600;
            color: rgba(78, 85, 102, 1);
        }
    }

    .non-trading-background {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: 0.3s;
        background-image: url("../../../../assets/NotSellableCardBg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px;
        opacity: 0.7;
    }
    .not-sellable {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 58px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        img {
            cursor: pointer;
            width: 58px;
        }

        .not-sellable-popup-wrapper {
            transition: 0.3s;
            transition-delay: 0.2s;
            opacity: 0;
            position: absolute;
            bottom: 150%;
            z-index: 15;

            .not-sellable-popup {
                min-height: 17px;
                max-height: 50px;
                padding: 4px;
                max-width: 200px;
                min-width: 128px;
                border-radius: 5px;
                background-color: #131722ab;
                font-size: 0.65em;
                color: white;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                white-space: wrap;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -4px;
                    width: 4px;
                    height: 4px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid #131722ab;
                }
            }
        }

        &:hover {
            .not-sellable-popup-wrapper {
                bottom: 190%;
                opacity: 1;
            }
        }
    }
}
