.report-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  row-gap: 14px;
  padding: 16px 20px;
  width: 100%;
  background: white;

  .report-item-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(43, 47, 56, 1);
      left: 16px;
    }

    img {
      cursor: pointer;
      width: 22px;
    }
  }

  .report-sign-contract {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    gap: 0px;

    img {
      height: 18px;
      margin-right: 5px;
      fill: #f8009e;
    }

    a {
      font-weight: 500;
      font-size: 14px;
      color: #000f30;
    }
  }
}
